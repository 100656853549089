// ForumComments.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Define the base API URL
const API_URL = "https://api.cpositive.org/";

const ForumComments = ({ topicId, onBack,userId }) => {
    const [currentTopic, setCurrentTopic] = useState(null);
    const [discussions, setDiscussions] = useState([]);
    const [newDiscussion, setNewDiscussion] = useState('');
    const [imageUrl, setImageUrl] = useState([])
    const [currentDiscussionPage, setCurrentDiscussionPage] = useState(1); // Current page number for discussions
    const itemsPerPage = 10; // Number of items (discussions) per page

    useEffect(() => {
        axios.get(`${API_URL}api/forum/topics/${topicId}`)
            .then(response => {
                setCurrentTopic(response.data);
                return axios.get(`${API_URL}api/forum/discussions/${topicId}`);
            })
            .then(discussionResponse => {
                setDiscussions(discussionResponse.data);
                setCurrentDiscussionPage(1); // Reset to first page when a new topic is selected                
            })
            .catch(error => console.error('Error fetching topic details or discussions:', error));
    }, [topicId]);

    const handleDiscussionSubmit = (event) => {
        event.preventDefault();
        axios.post(`${API_URL}api/forum/discussions`, {
            content: newDiscussion,
            topicId: currentTopic.id,
            userId: localStorage.getItem('userId')
        })
            .then(() => {
                // Refresh the discussion list after adding a new comment
                return axios.get(`${API_URL}api/forum/discussions/${currentTopic.id}`);
            })
            .then(discussionResponse => {
                setNewDiscussion('');
                setDiscussions(discussionResponse.data); // Update state with the refreshed discussion list
            })
            .catch(error => console.error('Error adding discussion or fetching updated discussions:', error));
    };

    const paginateDiscussions = (pageNumber) => setCurrentDiscussionPage(pageNumber);

    const indexOfLastDiscussion = currentDiscussionPage * itemsPerPage;
    const indexOfFirstDiscussion = indexOfLastDiscussion - itemsPerPage;
    const currentDiscussions = discussions.slice(indexOfFirstDiscussion, indexOfLastDiscussion);


useEffect(()=>{
    const getImageUrl=(id)=>`${API_URL}api/Forum/GetProfileImageByUserId/${id}`;
    const url = getImageUrl(userId);
        setImageUrl(url);
        // setError(false); // Reset error state when URL changes
        console.log('Fetching image from URL:', url);
    }, [userId]);

    const handleImageError = (e) => {
        console.error('Image load error:', e);
        // setError(true);
        e.target.onerror = null; // Prevent infinite loop
        e.target.src = 'http://via.placeholder.com/100'; // Fallback image URL
    };




    return (
        <div className='Pagebg'>
            <div className='row'>
                <div className='col-md-2'></div>
                <div className='col-md-8'>
                    {currentTopic ? (
                        <div>
                            <h2  className='Topics'>{currentTopic.content}</h2>
                            <b>By {currentTopic.users?.name}</b><br /><br />
                            <button className="btn submit" onClick={onBack}>Back</button>

                            <hr />
                            <h3 className='Topics'>Comments</h3>
                            <div className='forum-slider'>
                                {currentDiscussions.length > 0 ? (
                                    <ul className="list-group mb-4">
                                        {currentDiscussions.map(discussion => (
                                            <li className='list-group-item'>
                                            <div className='row'>
                                            <div className='col-md-2 userImg'>
                                              <img
                                        src={`${API_URL}api/Forum/GetProfileImageByUserId/${discussion.userId}`}  //${personalDetails.data[0].imagePath.replace(/\\/g, '/').replace(/images/g, '')}
                                        alt="User"
                                        className='userPhoto'
                                        onError={handleImageError}
// console.error('Image URL:', `${API_URL}api/OtherDetails/ProfileImage${personalDetails.data[0].imagePath.replace(/\\/g, '/').replace(/images/g, '')}`);
                                           />
                                           </div>
                                           <div key={discussion.id} className="col-md-9">
                                             <h4>{discussion.content}</h4> 
                                             <b>By {discussion.users?.name}</b>
                                            </div>
                                       
                                      </div>
                                       </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No discussions yet.</p>
                                )}

                                {/* Pagination for Discussions */}
                                {discussions.length > itemsPerPage && (
                                    <nav>
                                        <ul className="pagination">
                                            {[...Array(Math.ceil(discussions.length / itemsPerPage)).keys()].map(number => (
                                                <li key={number + 1} className="page-item">
                                                    <a href="#" onClick={() => paginateDiscussions(number + 1)} className="page-link">
                                                        {number + 1}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                )}
                            </div>
                            {/* Add Discussion Form */}
                            <form onSubmit={handleDiscussionSubmit} className="mb-4">
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        value={newDiscussion}
                                        onChange={(e) => setNewDiscussion(e.target.value)}
                                        placeholder="Add Comment"
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn submit">Add Comment</button>
                            </form>
                        </div>
                    ) : (
                        <p>Loading topic details...</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ForumComments;
