import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonalDetails from './PersonalDetails';
import MentalHealthOccupation from './MentalHealthOccupation'; // Import your FinalPage component

import PatientDetails from './PatientDetails';
import CancerInfo from './CancerInfo';
import OccupationDetails from './OccupationDetails';
import OrganizationDetails from './OrganizationDetails';
import TreatmentConductedAt from './TreatmentConductedAt';
import FinalPage from './FinalPage'; // Import your FinalPage component

export default function RegistrationSteps({ onLogin }) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(null);
  const API_URL = "https://api.cpositive.org/";

  useEffect(() => {
    onLogin();
    determineNextStep(); // Initial check on component mount
  }, []);

  useEffect(() => {
    if (currentStep === 'finalPage') {
      // Make API call when all conditions are satisfied and the final page is reached
      fetch(API_URL + 'api/OtherDetails/update-details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: localStorage.getItem('userId'),
          // Include other necessary fields here
        }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('API call successful:', data);
        })
        .catch(error => {
          console.error('Error making API call:', error);
        });
    }
  }, [currentStep]);

  const determineNextStep = () => {
    const userStatus = {
      registrationCompleted: localStorage.getItem('registrationCompleted'),
      personalDetailsCompleted: localStorage.getItem('personalDetails'),
      cPositive: localStorage.getItem('cPositive'),
      caregiver: localStorage.getItem('caregiver'),
      familyMember: localStorage.getItem('familyMember'),
      // cancerInfo: localStorage.getItem('cancerInfo'),
      // treatmentConducted: localStorage.getItem('treatmentConducted'),
      // patientDetails: localStorage.getItem('patientDetails'),
      volunteer: localStorage.getItem('volunteer'),
      organizationalDetails: localStorage.getItem('organizationalDetails'),
      healthcareProfessional: localStorage.getItem('healthcareProfessional'),
      mentalHealthProfessional: localStorage.getItem('mentalHealthProfessional'),
      // occupationalDetails: localStorage.getItem('occupationalDetails'),

      cpatientCancerInfo: localStorage.getItem('cpatientCancerInfo'),
      caregiverCancerInfo: localStorage.getItem('caregiverCancerInfo'),
      familyMemberCancerInfo: localStorage.getItem('familyMemberCancerInfo'),
      cpatientTreatmentConducted: localStorage.getItem('cpatientTreatmentConducted'),
      caregiverTreatmentConducted: localStorage.getItem('caregiverTreatmentConducted'),
      familyMemberTreatmentConducted: localStorage.getItem('familyMemberTreatmentConducted'),
      caregiverPatientDetail: localStorage.getItem('caregiverPatientDetail'),
      familyMemberPatientDetail: localStorage.getItem('familyMemberPatientDetail'),
      healthcareOccupationalDetails: localStorage.getItem('healthcareOccupationalDetails'),
      mentalHealthOccupationalDetails: localStorage.getItem('mentalHealthOccupationalDetails'),
    };

    // Check if registration is complete
    if (userStatus.registrationCompleted === "Y") {
      navigate('/FinalPage');
      return;
    }

    // Steps for different user roles
    if (userStatus.personalDetailsCompleted !== "Y") {
      setCurrentStep('personalDetails');
      return;
    }

    //  C Patient 
    if (userStatus.cPositive === "Y") {
    console.log("treatment 1 1: " + userStatus.caregiverTreatmentConducted );
    console.log("cancer : " + userStatus.cpatientCancerInfo );

      if (userStatus.cpatientCancerInfo !== "Y") {
        localStorage.setItem('usercategory','Cpatient');
        setCurrentStep('cancerInfo');
        console.log("treatment 2: " + userStatus.cpatientTreatmentConducted );
        return;
      } else if (userStatus.cpatientTreatmentConducted !== "Y") {
        localStorage.setItem('usercategory','Cpatient');
        console.log("treatment 3: " + userStatus.cpatientTreatmentConducted );

        setCurrentStep('treatmentConductedAt');
        return;
      }
    }

    // Caregiver
    if (userStatus.caregiver === "Y") {
      if (userStatus.caregiverPatientDetail !== "Y") {
        localStorage.setItem('usercategory','Caregiver');
        setCurrentStep('patientDetails');
        return;
      } else if (userStatus.caregiverCancerInfo !== "Y") {
        localStorage.setItem('usercategory','Caregiver');
        setCurrentStep('cancerInfo');
        return;
      } else if (userStatus.caregiverTreatmentConducted !== "Y") {
        localStorage.setItem('usercategory','Caregiver');
        setCurrentStep('treatmentConductedAt');
        return;
      }
    }

    // Family Member
    if (userStatus.familyMember === "Y") {
      // check In the caregiver that user is in family or not (after getting val from an care giver this can implement)
      if (userStatus.familyMemberPatientDetail !== "Y") {
        localStorage.setItem('usercategory','FamilyMember');
        setCurrentStep('patientDetails');
        return;
      } else if (userStatus.familyMemberCancerInfo !== "Y") {
        localStorage.setItem('usercategory','FamilyMember');
        setCurrentStep('cancerInfo');
        return;
      } else if (userStatus.familyMemberTreatmentConducted !== "Y") {
        localStorage.setItem('usercategory','FamilyMember');
        setCurrentStep('treatmentConductedAt');
        return;
      }

    }

    // Volunteer
    if (userStatus.volunteer === "Y" && userStatus.organizationalDetails !== "Y") {
      setCurrentStep('organizationDetails');
      return;
    }

    // healthcareProfessional
    if (userStatus.healthcareProfessional === "Y" && userStatus.healthcareOccupationalDetails !== "Y") {
      localStorage.setItem('usercategory','HealthcareProfessional');
      setCurrentStep('occupationDetails');
      return;
    }

    // healthcareProfessional
    if ((userStatus.mentalHealthProfessional === "Y") && userStatus.mentalHealthOccupationalDetails !== "Y") {
      localStorage.setItem('usercategory','MentalHealthProfessional');
      setCurrentStep('MentalHealthOccupation');
      return;
    }

    // If all conditions are satisfied, navigate to FinalPage
    setCurrentStep('finalPage');
  };

  const handleNavigation = () => {
    determineNextStep();
  };

  const handleStepCompletion = () => {
    determineNextStep(); // Recheck the next step after completing the current one
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 'personalDetails':
        return <PersonalDetails onComplete={handleStepCompletion} />;
      case 'cancerInfo':
        return <CancerInfo onComplete={handleStepCompletion} />;
      case 'patientDetails':
        return <PatientDetails onComplete={handleStepCompletion} />;
      case 'treatmentConductedAt':
        return <TreatmentConductedAt onComplete={handleStepCompletion} />;
      case 'organizationDetails':
        return <OrganizationDetails onComplete={handleStepCompletion} />;
      case 'occupationDetails':
        return <OccupationDetails onComplete={handleStepCompletion} />;
        case 'MentalHealthOccupation':
          return <MentalHealthOccupation onComplete={handleStepCompletion} />;
      case 'finalPage':
        return <FinalPage />;
      default:
        return null;
    }
  };

  return (
    <div>
      {currentStep === null ? (
        <>
          <h2>Welcome to Registration Steps</h2>
          <button onClick={handleNavigation}>Go to Next Step</button>
        </>
      ) : (
        renderCurrentStep()
      )}
    </div>
  );
}
