// Forum.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ForumComments from './ForumComments';

const API_URL = "https://api.cpositive.org/";

const Forum = ({ userId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [topics, setTopics] = useState([]);
    const [imageUrl, setImageUrl] = useState([]);
    const [commentCounts, setCommentCount] = useState({});
    const [error, setError] = useState(null);
    const [newTopic, setNewTopic] = useState({ title: '', content: '' });
    const [showAddTopicForm, setShowAddTopicForm] = useState(false);
    const [selectedTopicId, setSelectedTopicId] = useState(null);

    const handleSearch = async () => {
        if (searchTerm.trim() === "") {
            setSearchResults([]);
            return;
        }
        try {
            const response = await fetch(
                `${API_URL}api/Forum/SearchTopics?query=${searchTerm}`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setSearchResults(data);

        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };


    useEffect(() => {
        axios.get(`${API_URL}api/forum/topics`)
            .then(response => setTopics(response.data))
            .catch(error => console.error('Error fetching topics:', error));
    }, []);

    const handleTopicClick = (id) => {
        setSelectedTopicId(id);
    };

    const handleTopicSubmit = (event) => {
        event.preventDefault();
        axios.post(`${API_URL}api/forum/topics`, {
            title: newTopic.title,
            content: newTopic.content,
            userId: localStorage.getItem('userId')
        })
            .then(response => {
                console.log('Topic added successfully:', response.data);
                setNewTopic({ title: '', content: '' });
                setShowAddTopicForm(false);
                return axios.get(`${API_URL}api/forum/topics`);
            })
            .then(response => {
                setTopics(response.data);
            })
            .catch(error => console.error('Error adding topic:', error));
    };

    const handleBack = () => {
        setSelectedTopicId(null);
    };

    useEffect(() => {
        const getImageUrl = (id) => `${API_URL}api/Forum/GetProfileImageByUserId/${id}`;

        const url = getImageUrl(userId);
        setImageUrl(url);
        console.log('Fetching image from URL:', url);
    }, [userId]);

    const handleImageError = (e) => {
        console.error('Image load error:', e);
        e.target.onerror = null;
        e.target.src = 'http://via.placeholder.com/100';
    };

    const fetchCommentCount = async (topicId) => {
        try {
            const commentCountResponse = await fetch(`${API_URL}api/Forum/GetCommentCount/${topicId}`);
            if (!commentCountResponse.ok) {
                throw new Error(`Failed to fetch comment count for topic ID ${topicId}`);
            }
            const commentCountData = await commentCountResponse.json();
            setCommentCount((prevCounts) => ({
                ...prevCounts,
                [topicId]: commentCountData.commentCount,
            }));
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        if (topics.length > 0) {
            topics.forEach((topic) => {
                fetchCommentCount(topic.id);
            });
        }
    }, [topics]);

    return (
        <div className='Pagebg'>
            <div className='row'>
                <div className='col-md-12'>
                    <h2 className="text-center title mt-2">Forum</h2>
                    <hr />
                    {selectedTopicId ? (
                        <ForumComments
                            topicId={selectedTopicId}
                            onBack={handleBack}
                            searchResults={searchResults.length > 0 ? searchResults : topics.find(topic => topic.id === selectedTopicId)?.comments || []}
                        />
                    ) : (
                        <div className="row">
                            <div className='col-md-2'></div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-3">
                                        <button
                                            className="btn mt-3 submit-btn"
                                            onClick={() => setShowAddTopicForm(!showAddTopicForm)}
                                        >
                                            {showAddTopicForm ? 'Cancel' : 'Add New Discussion'}
                                        </button>
                                    </div>
                                    <div className="col-md-9 d-flex">
                                        {/* <input
                                            className="form-control searchbar"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <button className="btn search-btn px-2" type="submit" onClick={handleSearch}>
                                            Search
                                        </button> */}

                                        <input
                                            className="form-control searchbar"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)} // Update search term as the user types
                                        />
                                        <button
                                            className="btn search-btn px-2"
                                            type="button" // Use type="button" to prevent form submission
                                            onClick={handleSearch} // Handle search when button is clicked
                                        >
                                            Search
                                        </button>

                                    </div>
                                </div>
                                <br />

                                {showAddTopicForm && (
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <h2 className='Topics'>Add New Discussion</h2>
                                            <form onSubmit={handleTopicSubmit}>
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control"
                                                        value={newTopic.content}
                                                        onChange={(e) => setNewTopic({ ...newTopic, content: e.target.value })}
                                                        placeholder="Ask Question"
                                                        required
                                                    />
                                                </div>
                                                <button type="submit" className="btn submit">Submit</button>
                                                <hr />
                                            </form>
                                        </div>
                                    </div>
                                )}

                                <div className='forum-slider'>
                                    <ul className="list-group">
                                        {(searchTerm && searchResults.length > 0 ? searchResults : topics).map(topic => (
                                            <li key={topic.id} className="list-group-item">
                                                <a href="#" onClick={() => handleTopicClick(topic.id)}>
                                                    <div className='row'>
                                                        <div className='col-md-1 userImg'>
                                                            <img
                                                                src={`${API_URL}api/Forum/GetProfileImageByUserId/${topic.userId}`}
                                                                alt="User"
                                                                className='userPhoto'
                                                                onError={handleImageError}
                                                            />
                                                        </div>
                                                        <div className="col-md-10">
                                                            <h4>{topic.content}</h4>
                                                            <small><b>By: {topic.users?.name}</b></small>
                                                        </div>
                                                        <div className="col-md-1 d-flex">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-square" viewBox="0 0 16 16">
                                                                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2z" />
                                                            </svg>
                                                            <label className='label'>
                                                                {commentCounts[topic.id] || 0}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <br />
            <br />
        </div>
    );
};

export default Forum;
