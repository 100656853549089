import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Navbar = ({ isLoggedIn, handleLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const showLogoutOnly = [
    '/PersonalDetails',
    '/CancerInfo',
    '/TreatmentConductedAt',
    '/OccupationDetails',
    '/OrganizationDetails',
    '/PatientDetails',
    '/RegistrationSteps',
    '/FinalPage',
    '/Forum'
  ].includes(location.pathname);

  console.log('Current Path:', location.pathname);
  console.log('Show Logout Only:', showLogoutOnly);
  console.log('Is Logged In:', isLoggedIn);

  const handleLogoutClick = () => {
    navigate('/StartPage');
    handleLogout();
  };


  const handleForumClick = () => {
    navigate('/Forum');
  };
  return (
<header className="navbar pcoded-header navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid d-flex justify-content-between align-items-center">
    <div className="navbar-header">
      <a href="/" className="b-brand">
        <img src={`${process.env.PUBLIC_URL}/Assets/images/cpositivelogo.png`} alt="" className="logo" height="70" width="150" />
      </a>
    </div>

    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ml-auto d-flex align-items-center">
        <li className="nav-item">
          <div className="nav-buttons d-flex">
            {showLogoutOnly ? (
              isLoggedIn && (
                <>
                  <button className="btn nav-link" onClick={handleForumClick}>Forum</button>
                  <button className="btn nav-link" onClick={handleLogoutClick}>Logout</button>
                </>
              )
            ) : (
              <>
                <Link to="/login" className="nav-link">Login</Link>
                <Link to="/dashboard" className="nav-link">Join</Link>
              </>
            )}
          </div>
        </li>
      </ul>
    </div>
  </div>
</header>


  
  );
};

export default Navbar;
