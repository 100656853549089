import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const StartPage = () => {
  const navigate = useNavigate();

//   const handleRegister = () => {
//     navigate('/Dashboard');
//   };

//   const handleLogin = () => {
//     navigate('/login');
//   };

  return (
    <div>
      
      {/* <div>
        <button className="btn btn-primary" onClick={handleRegister}>
          Register
        </button>
        <button className="btn btn-secondary" onClick={handleLogin}>
          Login
        </button>
      </div> */}
    </div>
  );
};

export default StartPage;
