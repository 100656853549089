import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const API_URL = "https://api.cpositive.org/";
  const [EmailError, setEmailError] = useState('');
  const [PasswordError, setPasswordError] = useState('');
  const [email, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false); // State for showing modal

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  async function handleLogin() {
    if (!validateForm()) return;
    const enteredEmail = emailRef.current.value;
    const enteredPassword = passwordRef.current.value;

    const data = {
      emailId: enteredEmail,
      password: enteredPassword,
    };

    try {
      const response = await fetch(API_URL + "api/Authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("User data saved successfully:", result);
        onLogin(); // Set the isLoggedIn state to true
        // After saving data, proceed to authenticate
        await fetchUserStatus(enteredEmail, enteredPassword);
      } else {
        const errorText = await response.text();
        console.error("Save failed:", response.statusText, errorText);
        // Handle specific error cases if needed
        setShowModal(true); // Show modal for error
      }
    } catch (error) {
      console.error("Error during save:", error);
    }
  };

  const fetchUserStatus = async (emailId, password) => {
    try {
      const response = await fetch(API_URL + "api/Authenticate", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ emailId, password })
      });

      if (response.ok) {
        const userData = await response.json();
        console.log("User data fetched successfully:", userData);

        // Store user data in localStorage
        localStorage.setItem('token', userData.token);
        localStorage.setItem('userId', userData.userId);
        localStorage.setItem('username', userData.data.username);
        localStorage.setItem('emailId', userData.data.emailId);
        localStorage.setItem('cPositive', userData.data.cPositive);
        localStorage.setItem('cancerInfo', userData.data.cancerInfo);
        localStorage.setItem('caregiver', userData.data.caregiver);
        localStorage.setItem('familyMember', userData.data.familyMember);
        localStorage.setItem('healthcareProfessional', userData.data.healthcareProfessional);
        localStorage.setItem('mentalHealthProfessional', userData.data.mentalHealthProfessional);
        localStorage.setItem('organizationalDetails', userData.data.organizationalDetails);
        localStorage.setItem('occupationalDetails', userData.data.occupationalDetails);
        localStorage.setItem('patientDetails', userData.data.patientDetails);
        localStorage.setItem('personalDetails', userData.data.personaldetails);
        localStorage.setItem('registrationCompleted', userData.data.registrationCompleted);
        localStorage.setItem('treatmentConducted', userData.data.treatmentConducted);
        localStorage.setItem('volunteer', userData.data.volunteer);

        // Navigate to RegistrationSteps
        navigate('/RegistrationSteps');
      } else {
        console.error("Failed to authenticate:", response.statusText);
        // Handle specific error cases if needed
        setShowModal(true); // Show modal for error
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Enter Email Id");
      isValid = false;
    }

    if (!password) {
      setPasswordError("Enter Password");
      isValid = false;
    }
    return isValid;
  }

  const handleModalClose = () => {
    setShowModal(false);
    setEmailId('');
    setPassword('');
    setEmailError('');
    setPasswordError('');
  }

  return (
    <div>
      {/* Modal */}
      {showModal &&
  <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>
    <div className="modal-dialog" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '400px', width: '100%' }}>
      <div className="modal-content" style={{ textAlign: 'center', borderRadius: '8px', overflow: 'hidden' }}>
        <div className="modal-header" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 20px', backgroundColor: '#f0f0f0' }}>
          <h5 className="modal-title" style={{ fontSize: '1.6rem', margin: 0 }}>Invalid Login</h5>              
        </div>
        <div className="modal-body" style={{ padding: '20px', fontSize: '1.3rem', color: 'red' }}>
          <p style={{ marginBottom: '20px', fontSize: '1.1rem' }}>Invalid email or password. Please try again.</p>
          <button className="btn submit" onClick={handleModalClose} style={{ width: '80px', fontSize: '1rem', margin: '0 auto' }}>OK</button>
        </div>
      </div>
    </div>
  </div>
}




      {/* Login Form */}
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4 title'><br/><center>LOGIN</center></div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
          <div className='container'>
            <b><label>Email ID:</label></b>
            <input
              ref={emailRef}
              type="text"
              className="form-control"
              placeholder="Email Id"
              value={email}
              onChange={(e) => setEmailId(e.target.value)} />
            {EmailError && <p style={{ color: 'red', textAlign: 'left', marginBottom: '10px', fontSize: '0.9rem' }}>{EmailError}</p>}
            <br></br>
            <b><label>Password:</label></b>
            <input
              ref={passwordRef}
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
            {PasswordError && <p style={{ color: 'red', textAlign: 'left', marginBottom: '10px', fontSize: '0.9rem' }}>{PasswordError}</p>}
            <br />
            <center><button
              className="btn submit"
              type="button"
              onClick={handleLogin}
              style={{ width: '100px', fontSize: '1rem' }}>
              Login
            </button></center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
