import {useRef,useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

const MentalHealthOccupation = ({ onComplete }) => {
  const navigate = useNavigate();
  const API_URL = "https://api.cpositive.org/";
  const [qualification, setQualification] = useState('');
  const [specilization, setSpecilization] = useState('');
  const [expertiesName, setExperties] = useState('');
  const [experienceName, setExperience] = useState('');
  
  const qualificationRef = useRef(null);
  const specilizationRef = useRef(null);
  const expertiesRef = useRef(null);
  const experienceRef = useRef(null);

  console.log("print token: " + localStorage.getItem('token'));
 
  async function handlesave() {
    const qualifications = qualificationRef.current.value;
    const specilizations = specilizationRef.current.value;
    const expertiess = expertiesRef.current.value;
    const experiences = experienceRef.current.value;
    const data = {
        userId: localStorage.getItem('userId'),
        qualification: qualifications,
        specilization:specilizations,
        experties: expertiess,
        experience:experiences,
        category: localStorage.getItem('usercategory')
        };
  
    try {
      const response = await fetch(API_URL + "api/OtherDetails/OccupationDetails", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        const result = await response.json();
       
        if (localStorage.getItem('usercategory') === 'HealthcareProfessional') {
          localStorage.setItem('healthcareOccupationalDetails', 'Y');
        } else if (localStorage.getItem('usercategory') === 'MentalHealthProfessional') {
          localStorage.setItem('mentalHealthOccupationalDetails', 'Y');
        }

        console.log("occupational Details saved successfully:", result);
        onComplete();
      } else {
        console.error("Save failed");
      }
    } catch (error) {
      console.error("Error during save:", error);
    }
  }
  return (
    <>
    <div>
    <div className='Pagebg'>
      <br />
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4 title'><center>Mental health Professional - Occupation Details</center></div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
          <div className='container'>

          <b><label>Qualification:</label></b>
      <input
            ref={qualificationRef}
            type="text"
            className="form-control"
            placeholder="Qualification"
            value={qualification}
            onChange={(e) => setQualification(e.target.value)}
          />
            
           <b><label>Specilization:</label></b> 
      <input
            ref={specilizationRef}
            type="text"
            className="form-control"
            placeholder="Specilization"
            value={specilization}
            onChange={(e) => setSpecilization(e.target.value)}
          />

        <b><label>Experties:</label></b>
          <input
            ref={expertiesRef}
            type="text"
            className="form-control"
            placeholder="Experties (in 100Words)"
            value={expertiesName}
            onChange={(e) => setExperties(e.target.value)}
          />

         <b><label>Experience:</label></b>
          <input
            ref={experienceRef}
            type="text"
            className="form-control"
            placeholder="Experience (years)"
            value={experienceName}
            onChange={(e) => setExperience(e.target.value)}
          />
<br/>
         <center><button
            className="btn submit"
            type="button"
            onClick={handlesave}
          >
           Save & Next
          </button></center> 
</div>
</div>
</div>
    </div>
    </div>
    </>
  )
}

export default MentalHealthOccupation
