import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

const Registration = ({ checkboxes }) => {
  const navigate = useNavigate();
  const API_URL = "https://api.cpositive.org/";

  // Email regex for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // State variables for form inputs and errors
  const [emailId, setEmailId] = useState('');
  const [Name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [nameError, setNameError] = useState('');
  const [mobileNoError, setMobileNoError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');

  // Refs for form inputs
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const mobileNoRef = useRef(null);
  const nameRef = useRef(null);

  // Checkbox state
  const [firstCheckbox, secondCheckbox, thirdCheckbox, fourthCheckbox, fifthCheckbox, sixthCheckbox] = checkboxes;
  const isCPositive = firstCheckbox.value;
  const isCaregiver = secondCheckbox.value;
  const isFamilyMember = thirdCheckbox.value;
  const isVolunteer = fourthCheckbox.value;
  const isHealthcareProfessional = fifthCheckbox.value;
  const isMentalHealthProfessional = sixthCheckbox.value;

  const checkEmailIdExists = async (emailId) => {
    try {
      const response = await fetch(`${API_URL}api/CPatient/check-EmailId?emailId=${emailId}`);
      const data = await response.json();

      if (data.message === "EmailId already exists") {
        setEmailError('Email ID already exists');
        return true;
      } else {
        setEmailError('');
        return false;
      }
    } catch (error) {
      console.error("Error checking email ID:", error);
      setEmailError('Error checking email ID');
      return false;
    }
  };

  const debouncedCheckEmailIdExists = useCallback(debounce(checkEmailIdExists, 300), []);

  const checkMobileNoExists = async (mobileNo) => {
    try {
      const response = await fetch(`${API_URL}api/CPatient/check-mobileno?mobileno=${mobileNo}`);
      const data = await response.json();

      if (data.message === "Mobile number is already Exits !") {
        setMobileNoError('Mobile number already exists');
        return true;
      } else {
        setMobileNoError('');
        return false;
      }
    } catch (error) {
      console.error("Error checking mobile number:", error);
      setMobileNoError('Error checking mobile number');
      return false;
    }
  };

  const debouncedCheckMobileNoExists = useCallback(debounce(checkMobileNoExists, 300), []);

  const handleSave = async () => {
    if (!validateForm()) return;

    const emailExists = await checkEmailIdExists(emailId);
    if (emailExists) return;

    const mobileExists = await checkMobileNoExists(mobileNo);
    if (mobileExists) return;

    const data = {
      Name,
      password,
      confirmPassword,
      emailId,
      mobileNo,
      isCPositive,
      isCaregiver,
      isFamilyMember,
      isVolunteer,
      isHealthcareProfessional,
      isMentalHealthProfessional
    };

    try {
      const response = await fetch(API_URL + "api/CPatient/AddUsers", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("User data saved successfully:", result);

        await fetchUserStatus(emailId, password);
      } else {
        const errorText = await response.text();
        console.error("Save failed:", response.statusText, errorText);
      }
    } catch (error) {
      console.error("Error during save:", error);
    }
  };

  const fetchUserStatus = async (emailId, password) => {
    try {
      const response = await fetch(API_URL + "api/Authenticate", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ emailId, password })
      });

      if (response.ok) {
        const userData = await response.json();
        console.log("User data fetched successfully:", userData);

        localStorage.setItem('token', userData.token);
        localStorage.setItem('userId', userData.userId);
        localStorage.setItem('name', userData.data.name);
        localStorage.setItem('emailId', userData.data.emailId);
        localStorage.setItem('cPositive', userData.data.cPositive);
        localStorage.setItem('caregiver', userData.data.caregiver);
        localStorage.setItem('familyMember', userData.data.familyMember);
        localStorage.setItem('healthcareProfessional', userData.data.healthcareProfessional);
        localStorage.setItem('mentalHealthProfessional', userData.data.mentalHealthProfessional);
        localStorage.setItem('organizationalDetails', userData.data.organizationalDetails);
        localStorage.setItem('personalDetails', userData.data.personaldetails);
        localStorage.setItem('registrationCompleted', userData.data.registrationCompleted);
        localStorage.setItem('volunteer', userData.data.volunteer);

        localStorage.setItem('cpatientCancerInfo', userData.data.cpatientCancerInfo);
        localStorage.setItem('caregiverCancerInfo', userData.data.caregiverCancerInfo);
        localStorage.setItem('familyMemberCancerInfo', userData.data.familyMemberCancerInfo);
        localStorage.setItem('cpatientTreatmentConducted', userData.data.cpatientTreatmentConducted);
        localStorage.setItem('caregiverTreatmentConducted', userData.data.caregiverTreatmentConducted);
        localStorage.setItem('familyMemberTreatmentConducted', userData.data.familyMemberTreatmentConducted);
        localStorage.setItem('caregiverPatientDetail', userData.data.caregiverPatientDetail);
        localStorage.setItem('familyMemberPatientDetail', userData.data.familyMemberPatientDetail);
        localStorage.setItem('healthcareOccupationalDetails', userData.data.healthcareOccupationalDetails);
        localStorage.setItem('mentalHealthOccupationalDetails', userData.data.mentalHealthOccupationalDetails);

        navigate('/RegistrationSteps');
      } else {
        console.error("Failed to authenticate:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailId(newEmail);

    if (!emailRegex.test(newEmail)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
      debouncedCheckEmailIdExists(newEmail);
    }
  };

  const handleMobileNumberChange = (e) => {
    const newMobileNo = e.target.value;
    // Remove non-numeric characters
    const numericMobileNo = newMobileNo.replace(/\D/g, '');
    setMobileNo(numericMobileNo);

    if (numericMobileNo === '') {
      setMobileNoError('Mobile number is required');
    } else if (numericMobileNo.length < 10) {
      setMobileNoError('Mobile number should be at least 10 digits');
    } else {
      setMobileNoError('');
      debouncedCheckMobileNoExists(numericMobileNo);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    if (newPassword.length <= 16) {
      setPassword(newPassword);
      if (newPassword.length < 6) {
        setPasswordError('Password must be at least 6 characters long');
      }
      else {
        setPasswordError('');
      }
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Password does not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword !== password) {
      setConfirmPasswordError('Password does not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);

    if (newName.length < 3) {
      setNameError('Name should be at least 3 characters long');
    } else {
      setNameError('');
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (Name.length < 3) {
      setNameError('Name should be at least 3 characters long');
      isValid = false;
    }

    if (!emailRegex.test(emailId)) {
      setEmailError('Invalid email format');
      isValid = false;
    }

    if (mobileNo.trim() === '') {
      setMobileNoError('Mobile number is required');
      isValid = false;
    } else if (mobileNo.length < 10) {
      setMobileNoError('Mobile number should be at least 10 digits');
      isValid = false;
    }

    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Password does not match');
      isValid = false;
    }

    return isValid;
  };
  

  return (
    <>
      <br />
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4 title'><center>Create Account</center></div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
          <div className='container'>
            <span style={{ color: 'red' }}>*</span><b><label>Name:</label></b>
            <input
              ref={nameRef}
              type="text"
              className="form-control"
              placeholder="Name"
              value={Name}
              onChange={handleNameChange}
            />
            {nameError && <p style={{ color: 'red' }}>{nameError}</p>}

            <span style={{ color: 'red' }}>*</span><b><label>Email Id:</label></b>
            <input
              ref={emailRef}
              type="text"
              className="form-control"
              placeholder="Email Id"
              value={emailId}
              onChange={handleEmailChange}
            />
            {emailError && <p style={{ color: 'red' }}>{emailError}</p>}

            <span style={{ color: 'red' }}>*</span><b><label>Mobile No.:</label></b>
            <input
              ref={mobileNoRef}
              type="text"
              className="form-control"
              placeholder="Mobile No."
              value={mobileNo}
              onChange={handleMobileNumberChange}
            />
            {mobileNoError && <p style={{ color: 'red' }}>{mobileNoError}</p>}

            <span style={{ color: 'red' }}>*</span> <b><label>Password:</label></b>
            <input
              ref={passwordRef}
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
            {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}

            <span style={{ color: 'red' }}>*</span><b><label>Confirm Password:</label></b>
            <input
              ref={confirmPasswordRef}
              type="password"
              className="form-control"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {confirmPasswordError && <p style={{ color: 'red' }}>{confirmPasswordError}</p>}
            <br />
            <center><button
              className="btn btn-primary submit"
              type="button"
              onClick={handleSave}
            >Save & Next
            </button></center>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
