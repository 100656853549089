import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Components/Cssindex.css';

const PersonalDetails = ({ onComplete }) => {
  const navigate = useNavigate();
  const API_URL = "https://api.cpositive.org/";
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [selectedStateId, setSelectedStateId] = useState('');
  const [selectedDistrictId, setSelectedDistrictId] = useState('');
  const [countries, setCountries] = useState('');
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [Name, setName] = useState('');
  const [Pincode, setPincode] = useState('');
  const [Address, setAddress] = useState('');
  const [Age, setAge] = useState('');
  const [Gender, setGender] = useState('');
  const [Qualification, setQualification] = useState([]);
  const [Occupation, setOccupation] = useState([]);
  const [PincodeError, setPincodeError] = useState('');
  const [AgeError, setAgeError] = useState('');
  const [GenderError, setGenderError] = useState('');
  const [NameError, setNameError] = useState('');
  const [countriesError, setCountriesError] = useState('');
  const [DistrictError, setDistrictError] = useState('');
  const [StateError, setStateError] = useState('');
  const [Image, setImage] = useState(null);
  const [ImageError, setImageError] = useState('');

  const countryNameRef = useRef(null);
  const stateNameRef = useRef(null);
  const districtRef = useRef(null);
  const ageRef = useRef(null);
  const genderRef = useRef(null);
  const qualificationRef = useRef(null);
  const addressRef = useRef(null);
  const occupationRef = useRef(null);
  const pincodeRef = useRef(null);
  const nameRef = useRef(null);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectedStateId) {
      fetchDistricts(selectedStateId);
    }
  }, [selectedStateId]);

  const fetchCountries = async () => {
    try {
      const response = await fetch(API_URL + "api/CPatient/countries");
      if (!response.ok) {
        throw new Error("Failed to fetch countries. Status: " + response.status);
      }
      const result = await response.json();
      setCountries(result.data || []);
    } catch (error) {
      console.error("Error during countries fetching:", error.message);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await fetch(API_URL + `api/CPatient/states/${countryId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch states. Status: " + response.status);
      }
      const result = await response.json();
      setStates(result.GetStatesData || []);
    } catch (error) {
      console.error("Error during states fetching:", error.message);
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const response = await fetch(API_URL + `api/CPatient/districts/${stateId}`);
      console.log("stateid for district is " + stateId)
      if (!response.ok) {
        throw new Error("Failed to fetch districts. Status: " + response.status);
      }
      const result = await response.json();
      setDistricts(result.GetDistrictsData || []);
    } catch (error) {
      console.error("Error during districts fetching:", error.message);
    }
  };

  const handleDropdownCountry = (event) => {
    const newCountry = event.target.value;
    setSelectedCountryId(newCountry);
    if (newCountry === '') {
      setCountriesError('');
    } else {
      setCountriesError('');
    }
    setStates([]); // Clear states when country changes
    setDistricts([]); // Clear districts when country changes
    setSelectedStateId(''); // Reset selected state
    setSelectedDistrictId(''); // Reset selected district
  };

  const handleDropdownState = (event) => {
    const newState = event.target.value;
    setSelectedStateId(newState);
    if (newState === '') {
      setStateError('');
    } else {
      setStateError('');
    }
    setDistricts([]); // Clear districts when state changes
    setSelectedDistrictId(''); // Reset selected district
  };

  const handleDropdownDistrict = (event) => {
    const newDistrict = event.target.value;
    setSelectedDistrictId(newDistrict);
    if (newDistrict === '') {
      setDistrictError('');
    } else {
      setDistrictError('');
    }
  };

  // Generate array of ages from 1 to 100
  const ages = Array.from({ length: 110 }, (_, i) => i + 1);

  // Gender options
  const genders = ['Male', 'Female', 'Other'];

  async function handlesave() {
    if (!validateForm()) return;

    const data = new FormData();
    data.append('UserId', localStorage.getItem('userId'));
    data.append('Name', localStorage.getItem('name'));
    data.append('CountryId', selectedCountryId);
    data.append('StateId', selectedStateId);
    data.append('DistrictId', selectedDistrictId);
    data.append('Address', addressRef.current.value);
    data.append('Pincode', pincodeRef.current.value);
    data.append('Age', ageRef.current.value); // Ensure age is sent as an integer
    data.append('Gender', genderRef.current.value);
    data.append('HighestQualification', qualificationRef.current.value);
    data.append('Occupation', occupationRef.current.value);
    data.append('image', Image);
    // if (Image) {
    //   data.append('image', Image);
    // }
    // else{
    //   data.append('image', "null");
    // }
    console.log("IMG PATH : " + Image);

    try {
      const response = await fetch(API_URL + "api/CPatient/add-personal-details", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: data,
      });
      if (response.ok) {
        const result = await response.json();
        localStorage.setItem('personalDetails', 'Y');
        localStorage.setItem('PatientSubheading', 'Enter your cancer information.');
        localStorage.setItem('TreatmentSubheading', 'Enter details where you taking treatment.');
        console.log("Personal Details saved successfully:", result);
        onComplete();
      } else {
        const errorText = await response.text();
        console.error("Save failed:", response.statusText, errorText);
      }
    } catch (error) {
      console.error("Error during save:", error);
    }
  }

  const handlepincodeChange = (e) => {
    const newPincode = e.target.value;

    if (/^\d*$/.test(newPincode)) {
      setPincode(newPincode);
      if (newPincode === '') {
        setPincodeError('');
      } else {
        setPincodeError('');
      }
    } else {
      setPincodeError('Numbers only');
    }
  };

  const handleAgeChange = (event) => {
    const newAge = event.target.value;
    setAge(newAge);
    if (newAge === '') {
      setAgeError('');
    } else {
      setAgeError('');
    }
  }

  const handleGenderChange = (event) => {
    const newGender = event.target.value;
    setGender(newGender);
    if (newGender === '') {
      setGenderError('');
    } else {
      setGenderError('');
    }
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors = { username: '', email: '', districtId: '' };

    if (!Pincode) {
      setPincodeError('Pincode is required');
      isValid = false;
    } else {
      setPincodeError('');
    }

    if (!selectedDistrictId) {
      setDistrictError('Select District');
      isValid = false;
    } else {
      setDistrictError('');
    }

    if (!selectedStateId) {
      setStateError('Select State');
      isValid = false;
    } else {
      setStateError('');
    }

    if (!selectedCountryId) {
      setCountriesError('Select country');
      isValid = false;
    } else {
      setCountriesError('');
    }

    if (!Gender) {
      setGenderError('Select Gender');
      isValid = false;
    } else {
      setGenderError('');
    }

    // if (!Name) {
    //   setNameError('Name is required');
    //   isValid = false;
    //   }

    if (!Age) {
      setAgeError('Select Age');
      isValid = false;
    } else {
      setAgeError('');
    }

    // if (!Image) {
    //   setImageError('Upload an image');
    //   isValid = false;
    // }else{
    //   setImageError('');
    // }

    // setNameError(newErrors);
    return isValid;
  };

  return (
    <>
      <div className='Pagebg'>
        <br />
        <div className='row'>
          <div className='col-md-4'></div>
          <div className='col-md-4 title'><center>PERSONAL DETAILS</center></div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-6'>
            <div className='container'>
              <br />
              <b><label className='label'>Name:</label></b>
              <input
                ref={nameRef}
                type="text"
                className="form-control"
                placeholder="Name"
                value={localStorage.getItem('name')}
                onChange={(e) => setName(e.target.value)}
                disabled="true"
              />
              {NameError && <p style={{ color: 'red' }}>{NameError}</p>}

              <span style={{ color: 'red' }}>*</span><b><label className='label'>Country:</label></b>
              <select
                className="form-control"
                value={selectedCountryId}
                onChange={handleDropdownCountry}
                ref={countryNameRef}
              >
                <option key="default" value="">Select Country</option>
                {countries.length > 0 ? (
                  countries.map((country, index) => (
                    <option
                      key={country.countryId || index}
                      value={country.countryId}
                    >
                      {country.countryName}
                    </option>
                  ))
                ) : (
                  <option>Loading countries...</option>
                )}
              </select>
              {countriesError && <p style={{ color: 'red' }}>{countriesError}</p>}

              <span style={{ color: 'red' }}>*</span><b><label className='label'>State:</label></b>
              <select
                className="form-control"
                value={selectedStateId}
                onChange={handleDropdownState}
                ref={stateNameRef}
              >
                <option key="default" value="">Select State</option>
                {states.length > 0 ? (
                  states.map((state, index) => (
                    <option
                      key={state.stateid || index}
                      value={state.stateid}
                    >
                      {state.statename}
                    </option>
                  ))
                ) : (
                  <option>Loading states...</option>
                )}
              </select>
              {StateError && <p style={{ color: 'red' }}>{StateError}</p>}

              <span style={{ color: 'red' }}>*</span><b><label className='label'>District:</label></b>
              <select
                className="form-control"
                value={selectedDistrictId}
                onChange={handleDropdownDistrict}
                ref={districtRef}
              >
                <option key="default" value="">Select District</option>
                {districts.length > 0 ? (
                  districts.map((district, index) => (
                    <option
                      key={district.districtid || index}
                      value={district.districtid}
                    >
                      {district.districtname}
                    </option>
                  ))
                ) : (
                  <option>Loading districts...</option>
                )}
              </select>
              {DistrictError && <p style={{ color: 'red' }}>{DistrictError}</p>}

              <b><label className='label'>Address:</label></b>
              <input
                ref={addressRef}
                type="text"
                className="form-control"
                placeholder="Address"
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
              />

              <span style={{ color: 'red' }}>*</span><b><label className='label'>Pin Code:</label></b>
              <input
                ref={pincodeRef}
                type="text"
                className="form-control"
                placeholder="Pin Code"
                value={Pincode}
                onChange={handlepincodeChange}
              />
              {PincodeError && <p style={{ color: 'red' }}>{PincodeError}</p>}

              <span style={{ color: 'red' }}>*</span><b><label className='label'>Age:</label></b>
              <select
                ref={ageRef}
                className="form-control"
                value={Age}
                onChange={handleAgeChange}
              >
                <option key="default" value="">Select Age</option>
                {ages.map((age, index) => (
                  <option key={index} value={age}>
                    {age}
                  </option>
                ))}
              </select>
              {AgeError && <p style={{ color: 'red' }}>{AgeError}</p>}

              <span style={{ color: 'red' }}>*</span><b><label className='label'>Gender:</label></b>
              <select
                ref={genderRef}
                className="form-control"
                value={Gender}
                onChange={handleGenderChange}
              >
                <option key="default" value="">Select Gender</option>
                {genders.map((gender, index) => (
                  <option key={index} value={gender}>
                    {gender}
                  </option>
                ))}
              </select>
              {GenderError && <p style={{ color: 'red' }}>{GenderError}</p>}

              <b><label className='label'>Highest Qualification:</label></b>
              <input
                ref={qualificationRef}
                type="text"
                className="form-control"
                placeholder="Qualification"
                value={Qualification}
                onChange={(e) => setQualification(e.target.value)}
              />

              <b><label className='label'>Occupation:</label></b>
              <input
                ref={occupationRef}
                type="text"
                className="form-control"
                placeholder="Occupation"
                value={Occupation}
                onChange={(e) => setOccupation(e.target.value)}
              />

              <b><label className='label'>Upload Image:</label></b>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setImage(e.target.files[0])}
              />
              {ImageError && <p style={{ color: 'red' }}>{ImageError}</p>}

              <br />
              <center>
                <button type="submit" className="btn submit" onClick={handlesave}>Save & Next</button>
              </center>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
