import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Components/Cssindex.css';

const CancerInfo = ({ onComplete }) => {
  const navigate = useNavigate();
  const API_URL = "https://api.cpositive.org/";
  const [cancerTypes, setCancerType] = useState([]);
  const [cancerTypeId, setCancerTypeId] = useState('');
  const [CancerTypeError, setCancerTypeError] = useState('');
  const [cancerNames, setCancerNames] = useState([]);
  const [cancerNamesId, setCancerNamesId] = useState('');
  const [cancerNameError, setCancerNameError] = useState('');
  const [stages, setStages] = useState([]);
  const [stageId, setStageId] = useState('');
  const [stagenameError, setstagenameError] = useState('');
  const [grades, setGrades] = useState([]);
  const [gradeId, setGradeId] = useState('');
  const [gradeNameError, setgradeNameError] = useState('');
  const [selected, setSelected] = useState(null);

  const [isFirstTime, setIsFirstTime] = useState(false);
  const [isRelapsed, setIsRelapsed] = useState(false);
  const [isTreatmentOngoing, setIsTreatmentOngoing] = useState(false);
  const [isSurgery, setIsSurgery] = useState(false);
  const [isChemo, setIsChemo] = useState(false);
  const [isRadiation, setIsRadiation] = useState(false);
  const [isTargetedTherapy, setIsTargetedTherapy] = useState(false);
  const [isPallitiveCare, setIsPallitiveCare] = useState(false);
  const [isRemission, setIsRemission] = useState(false);

  const cancerTypeIdRef = useRef(null);
  const cancerNameIdRef = useRef(null);
  const stageIdRef = useRef(null);
  const gradeIdRef = useRef(null);

  console.log("print token: " + localStorage.getItem('token'));

  useEffect(() => {
    fetchCancerTypes();
  }, []);

  const fetchCancerTypes = async () => {
    try {
      const response = await fetch(API_URL + "api/CancerDetails/CancerType");
      if (!response.ok) {
        throw new Error("Failed to fetch countries. Status: " + response.status);
      }
      const result = await response.json();
      setCancerType(result.GetCancerTypeData || []);
    } catch (error) {
      console.error("Error during countries fetching:", error.message);
    }
  };

  const handleDropdownCancerType = (event) => {
    const newCancerType = event.target.value;
    setCancerTypeId(newCancerType);
    if (newCancerType === '') {
      setCancerTypeError('');
    } else {
      setCancerTypeError('');
    }
  };

  useEffect(() => {
   
    fetchCancerNames();
  }, []);

  const fetchCancerNames = async () => {
    try {
      const response = await fetch(API_URL + "api/CancerDetails/CancerName");
      if (!response.ok) {
        throw new Error("Failed to fetch countries. Status: " + response.status);
      }
      const result = await response.json();
      setCancerNames(result.GetCancerNameData || []);
    } catch (error) {
      console.error("Error during countries fetching:", error.message);
    }
  };

  const handleDropdownCancerName = (event) => {
    const newCancerName = event.target.value;
    setCancerNamesId(newCancerName);
    if (newCancerName === '') {
      setCancerNameError('');
    } else {
      setCancerNameError('');
    }
  };

  useEffect(() => {
    fetchCancerStage();
  }, []);

  const fetchCancerStage = async () => {
    try {
      const response = await fetch(API_URL + "api/CancerDetails/CancerStages");
      if (!response.ok) {
        throw new Error("Failed to fetch countries. Status: " + response.status);
      }
      const result = await response.json();
      setStages(result.GetCancerStagesData || []);
    } catch (error) {
      console.error("Error during countries fetching:", error.message);
    }
  };

  const handleDropdownSatge = (event) => {
    const newStages = event.target.value;
    setStageId(newStages);
    if (newStages === '') {
      setstagenameError('');
    } else {
      setstagenameError('');
    }
  };

  useEffect(() => {
    fetchGarde();
  }, []);

  const fetchGarde = async () => {
    try {
      const response = await fetch(API_URL + "api/CancerDetails/CancerGrades");
      if (!response.ok) {
        throw new Error("Failed to fetch countries. Status: " + response.status);
      }
      const result = await response.json();
      setGrades(result.GetCancerGradesData || []);
    } catch (error) {
      console.error("Error during countries fetching:", error.message);
    }
  };

  const handleDropdownGrade = (event) => {
    const newGrade = event.target.value;
    setGradeId(newGrade);
    if (newGrade === '') {
      setgradeNameError('');
    } else {
      setgradeNameError('');
    }
  };

  const handleSave = async () => {
    if (!validateForm()) return;
    const data = {
      userId: localStorage.getItem('userId'),
      cancertypeId: cancerTypeIdRef.current.value,
      cancerNameId: cancerNameIdRef.current.value,
      stageId: stageIdRef.current.value,
      gradeId: gradeIdRef.current.value,
      isFirstTime: isFirstTime ? 'Y' : 'N',
      isRelapsed: isRelapsed ? 'Y' : 'N',
      isTreatmentOngoing: isTreatmentOngoing ? 'Y' : 'N',
      isSurgery: isSurgery ? 'Y' : 'N',
      isChemo: isChemo ? 'Y' : 'N',
      isRadiation: isRadiation ? 'Y' : 'N',
      isTargetedTherapy: isTargetedTherapy ? 'Y' : 'N',
      isPallitiveCare: isPallitiveCare ? 'Y' : 'N',
      isRemission: isRemission ? 'Y' : 'N',
      category: localStorage.getItem('usercategory')
    };

    try {
      const response = await fetch(API_URL + "api/CancerDetails/CancerDetails", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        if (localStorage.getItem('usercategory') === 'Cpatient') {
          localStorage.setItem('cpatientCancerInfo', 'Y');
        } else if (localStorage.getItem('usercategory') === 'Caregiver') {
          localStorage.setItem('caregiverCancerInfo', 'Y');
        } else if (localStorage.getItem('usercategory') === 'FamilyMember') {
          localStorage.setItem('familyMemberCancerInfo', 'Y');
        }


        console.log("Cancer Details saved successfully:", result);
        onComplete();
      } else {
        const errorData = await response.json();
        console.error("Save failed:", errorData);
      }
    } catch (error) {
      console.error("Error during save:", error);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!cancerNamesId) {
      setCancerNameError("Select Cancer Name");
      isValid = false;
    }

    if (!cancerTypeId) {
      setCancerTypeError("Select Cancer Type");
      isValid = false;
    }

    if (!stageId) {
      setstagenameError("Select Cancer Stage");
      isValid = false;
    }

    if (!gradeId) {
      setgradeNameError("Select Cancer Grade");
      isValid = false;
    }
    return isValid;
  };

  const handleCheckboxChange = (option) => {
    if (option === 'isFirstTime') {
      setIsFirstTime(true);
      setIsRelapsed(false);
    } else if (option === 'isRelapsed') {
      setIsRelapsed(true);
      setIsFirstTime(false);
    }
  };


  return (
    <>
      <div>
        <div className='Pagebg'>
          <br />
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4 title'><center>{localStorage.getItem('usercategory')} - Cancer Information</center></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              <div className='container'>
                <h4>{localStorage.getItem('PatientSubheading')}</h4>
                <br />

                <div className='row'>
                  <div className='col-md-6'>
                    <span style={{ color: 'red' }}>*</span>
                    <lable><b>Cancer Type:</b></lable>
                    <select
                      className="form-control"
                      value={cancerTypeId}
                      onChange={handleDropdownCancerType}
                      ref={cancerTypeIdRef}>
                      <option key="default" value="">Select Cancer Type</option>
                      {cancerTypes.length > 0 ? (
                        cancerTypes.map((CancerType, index) => (
                          <option
                            key={index}
                            value={CancerType.cancerTypeId}>
                            {CancerType.cancerType}
                          </option>
                        ))
                      ) : (
                        <option>Loading...</option>
                      )}
                    </select>
                    {CancerTypeError && <p style={{ color: 'red' }}>{CancerTypeError}</p>}
                  </div>

                  <div className='col-md-6'>
                    <span style={{ color: 'red' }}>*</span>
                    <lable><b>Cancer Name:</b></lable>
                    <select
                      className="form-control"
                      value={cancerNamesId}
                      onChange={handleDropdownCancerName}
                      ref={cancerNameIdRef}>
                      <option key="default" value="">Select Cancer Name</option>
                      {cancerNames.length > 0 ? (
                        cancerNames.map((cancerName, index) => (
                          <option
                            key={index}
                            value={cancerName.cancerNameId}>
                            {cancerName.cancerName}
                          </option>
                        ))
                      ) : (
                        <option>Loading...</option>
                      )}
                    </select>
                    {cancerNameError && <p style={{ color: 'red' }}>{cancerNameError}</p>}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <span style={{ color: 'red' }}>*</span>
                    <lable><b>Stage:</b></lable>
                    <select
                      className="form-control"
                      value={stageId}
                      onChange={handleDropdownSatge}
                      ref={stageIdRef}>
                      <option key="default" value="">Select Stage</option>
                      {stages.length > 0 ? (
                        stages.map((stagename, index) => (
                          <option
                            key={index}
                            value={stagename.stageId}>
                            {stagename.stagename}
                          </option>
                        ))
                      ) : (
                        <option>Loading...</option>
                      )}
                    </select>
                    {stagenameError && <p style={{ color: 'red' }}>{stagenameError}</p>}
                  </div>

                  <div className='col-md-6'>
                    <span style={{ color: 'red' }}>*</span>
                    <lable><b>Grade:</b></lable>
                    <select
                      className="form-control"
                      value={gradeId}
                      onChange={handleDropdownGrade}
                      ref={gradeIdRef}>
                      <option key="default" value="">Select Grade</option>
                      {grades.length > 0 ? (
                        grades.map((gradeName, index) => (
                          <option
                            key={index}
                            value={gradeName.gradeId}>
                            {gradeName.gradeName}
                          </option>
                        ))
                      ) : (
                        <option>Loading...</option>
                      )}
                    </select>
                    {gradeNameError && <p style={{ color: 'red' }}>{gradeNameError}</p>}
                  </div>
                </div>
                <br />


                <div className='row'>
                  <div className='col-md-2'>
                    <label>
                      <input
                        type="checkbox"
                        name="option"
                        checked={isFirstTime}
                        onChange={() => handleCheckboxChange('isFirstTime')}
                      />
                      First Time
                    </label>
                  </div>

                  <div className='col-md-3'>
                    <label>
                      <input
                        type="checkbox"
                        name="option"
                        checked={isRelapsed}
                        onChange={() => handleCheckboxChange('isRelapsed')}
                      />
                      Relapsed
                    </label>
                  </div>
                </div>

                <div className='Presentpad'>
                  <lable ><b>Present Condition/Status :</b></lable>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isTreatmentOngoing}
                        onChange={(e) => setIsTreatmentOngoing(e.target.checked)}
                      />
                      Ongoing Treatment :
                    </label>
                  </div>
                </div>

                <div className='row space'>
                  <div className='col-md-2'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isSurgery}
                        onChange={(e) => setIsSurgery(e.target.checked)}
                      />
                      Surgery
                    </label>
                  </div>

                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isChemo}
                        onChange={(e) => setIsChemo(e.target.checked)}
                      />
                      Chemo
                    </label>
                  </div>
                </div>

                <div className='row space-bar'>
                  <div className='col-md-2'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isRadiation}
                        onChange={(e) => setIsRadiation(e.target.checked)}
                      />
                      Radiation
                    </label>
                  </div>

                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isTargetedTherapy}
                        onChange={(e) => setIsTargetedTherapy(e.target.checked)}
                      />
                      Targeted Therapy
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isPallitiveCare}
                        onChange={(e) => setIsPallitiveCare(e.target.checked)}
                      />
                      Palliative Care
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isRemission}
                        onChange={(e) => setIsRemission(e.target.checked)}
                      />
                      Remission
                    </label>
                  </div>
                </div>
                <br />
                <center><button className="btn submit" type="button" onClick={handleSave}>
                  Save & Next
                </button></center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancerInfo;
