import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Components/Cssindex.css';

const TreatmentConductedAt = ({ onComplete }) => {
  console.log("treatment 4: ");

  const navigate = useNavigate();
  const API_URL = "https://api.cpositive.org/";

  const [entries, setEntries] = useState([{ hospitalName: '', oncologistName: '',  category: localStorage.getItem('usercategory') }]);

  const handleInputChange = (index, field, value) => {
    const newEntries = [...entries];
    newEntries[index][field] = value;
    setEntries(newEntries);
  };

  const addNewEntry = () => {
    setEntries([...entries, { hospitalName: '', oncologistName: '', category: localStorage.getItem('usercategory') }]);
  };

  const handlesave = async () => {
    const data = {
      userId: localStorage.getItem('userId'),
      treatments: entries
    };

    console.log("Data to be sent:", data); // Debugging info

    try {
      const response = await fetch(API_URL + "api/CancerDetails/CancerTreatement", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {

        if (localStorage.getItem('usercategory') === 'Cpatient') {
          localStorage.setItem('cpatientTreatmentConducted', 'Y');
        }
        if (localStorage.getItem('usercategory') === 'Caregiver') {
          localStorage.setItem('caregiverTreatmentConducted', 'Y');
        }
        if (localStorage.getItem('usercategory') === 'FamilyMember') {
          localStorage.setItem('familyMemberTreatmentConducted', 'Y');
        }

        const result = await response.json();
        localStorage.setItem('treatmentConducted', 'Y');
        console.log("Treatment details saved successfully:", result);
        onComplete();
      } else {
        const errorText = await response.text(); // Get error response text for more details
        console.error("Save failed:", response.status, response.statusText, errorText);
      }
    } catch (error) {
      console.error("Error during save:", error);
    }
  };

  const handleSkip = () => {
    navigate('/next-page'); // Replace '/next-page' with the actual path to the next page
  };

  return (
    <>
      <div className='Pagebg'>
        <br />
        <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-6 title'><center>{localStorage.getItem('usercategory')} - Treatment Conducted At </center></div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-6'>
            <div className='container'>
              {entries.map((entry, index) => (
                <div key={index}>
                  <h4>{localStorage.getItem('TreatmentSubheading')}</h4><br />
                  <b><label>Hospital Name:</label></b>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Hospital Name"
                    value={entry.hospitalName}
                    onChange={(e) => handleInputChange(index, 'hospitalName', e.target.value)}
                  />
                  <b><label>Oncologist Name:</label></b>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Oncologist Name"
                    value={entry.oncologistName}
                    onChange={(e) => handleInputChange(index, 'oncologistName', e.target.value)}
                  />
                  <br />
                </div>
              ))}
              <button
                className="btn btn-secondary"
                type="button"
                onClick={addNewEntry}
              >
                Add New+
              </button>
              <br />
              <div className="button-container" style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  className="btn submit"
                  type="button"
                  onClick={handlesave}
                  style={{ marginRight: '10px' }}
                >
                  Save & Next
                </button>
                {/* <button
                  className="btn submit button"
                  type="button"
                  onClick={handleSkip}
                >
                  Skip
                </button> */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TreatmentConductedAt;
