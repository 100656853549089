import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import DashBoard from './Components/DashBoard';
import Registration from './Components/Registration';
import PersonalDetails from './Components/PersonalDetails';
import CancerInfo from './Components/CancerInfo';
import TreatmentConductedAt from './Components/TreatmentConductedAt';
import OccupationDetails from './Components/OccupationDetails';
import OrganizationDetails from './Components/OrganizationDetails';
import Login from './Components/Login';
import PatientDetails from './Components/PatientDetails';
import Navbar from './Components/Navbar';
import RegistrationSteps from './Components/RegistrationSteps';
import FinalPage from './Components/FinalPage';
import StartPage from './Components/StartPage';
import MentalHealthOccupation from './Components/MentalHealthOccupation';
import Forum from './Components/Forum';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  }); 

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);


  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');    
    // Perform any additional logout logic
  };

  return (
    <Router>
      <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/Registration" element={<Registration />} />
        <Route path="/PersonalDetails" element={<PersonalDetails />} />
        <Route path="/CancerInfo" element={<CancerInfo />} />
        <Route path="/TreatmentConductedAt" element={<TreatmentConductedAt />} />
        <Route path="/OccupationDetails" element={<OccupationDetails />} />
        <Route path="/OrganizationDetails" element={<OrganizationDetails />} />
        <Route path="/login" element={<Login onLogin={() => setIsLoggedIn(true)} />} />
        <Route path="/PatientDetails" element={<PatientDetails />} />
        <Route path="/RegistrationSteps" element={<RegistrationSteps onLogin={() => setIsLoggedIn(true)} />} />
        <Route path="/FinalPage" element={<FinalPage />} />
        <Route path="/Menta1lHealthOccupation" element={<MentalHealthOccupation />} />
        <Route path="/Forum" element={<Forum />} />

      </Routes>
    </Router>
  );
}

export default App;
