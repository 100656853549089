import React from 'react';

function CheckboxList({ checkboxes, onCheckboxChange }) {
  return (

    <>
      {/* <div className='checkbox-grid'>
        {checkboxes.map(checkbox => (
          <label key={checkbox.id} className='checkbox-item'>
            <input
              type="checkbox"
              checked={checkbox.value === 'Y'}
              onChange={() => onCheckboxChange(checkbox.id)}
            />
            <center className='checkbox'>{checkbox.label}</center>

          </label>
        ))}       
      </div> */}

      <center>
        <br />
        <div className="checkbox-grid">
          {checkboxes.map(checkbox => (
            <div key={checkbox.id} className="checkbox-wrapper-37">
              <input
                type="checkbox"
                name={`checkbox-${checkbox.id}`}
                id={`terms-checkbox-${checkbox.id}`}
                checked={checkbox.value === 'Y'}
                onChange={() => onCheckboxChange(checkbox.id)}
              />
              <label htmlFor={`terms-checkbox-${checkbox.id}`} className="terms-label">
                <svg
                  className="checkbox-svg"
                  viewBox="0 0 200 200"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id={`path-1-inside-1_476_5-${checkbox.id}`} fill="white">
                    <rect width="200" height="200" />
                  </mask>
                  <rect
                    width="160"
                    height="160"
                    className="checkbox-box"
                    strokeWidth="20"
                    mask={`url(#path-1-inside-1_476_5-${checkbox.id})`}
                  />
                  <path
                    className="checkbox-tick"
                    d="M52 111.018L76.9867 136L149 64"
                    strokeWidth="20"
                  />
                </svg>
                <span className="label-text">{checkbox.label}</span>
              </label>
            </div>
          ))}
        </div>
      </center>

    </>
  );
}

export default CheckboxList;
