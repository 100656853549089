import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const PatientDetails = ({ onComplete }) => {
  const navigate = useNavigate();
  const API_URL = "https://api.cpositive.org/";
  const [patientsName, setPatientname] = useState('');
  const [Age, setAge] = useState(''); // Default age
  const [Gender, setGender] = useState('');
  const [relsWithPatient, setRelPatient] = useState('');
  const [errors, setErrors] = useState({});
  const [RelsError, setRelsError] = useState('');
  const [PatientError, setPatientError] = useState('');
  const [AgeError, setAgeError] = useState('');
  const [GenderError, setGenderError] = useState('');

  const patientref = useRef(null);
  const ageRef = useRef(null);
  const genderRef = useRef(null);
  const relWithPatientref = useRef(null);

  console.log("print token: " + localStorage.getItem('token'));

  const ages = Array.from({ length: 110 }, (_, i) => i + 1);

  // Gender options
  const genders = ['Male', 'Female', 'Other'];

  const validate = () => {
    const newErrors = {};

    if (!patientsName.trim()) newErrors.patientsName = 'Patient name is required';
    if (!Age) newErrors.Age = 'Age is required';
    if (!Gender) newErrors.Gender = 'Gender is required';
    if (!relsWithPatient.trim()) newErrors.relsWithPatient = 'Relation with patient is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  async function handlesave() {
    if (!validateForm()) return;

    const patientnames = patientref.current.value;
    const relWithPatients = relWithPatientref.current.value;
    const data = {
      userId: localStorage.getItem('userId'),
      patientName: patientnames,
      age: parseInt(ageRef.current.value), // Ensure age is sent as an integer
      gender: genderRef.current.value,
      relWithPatient: relWithPatients,
      category: localStorage.getItem('usercategory')
    };

    try {
      const response = await fetch(API_URL + "api/OtherDetails/PatientDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {

        if (localStorage.getItem('usercategory') === 'Caregiver') {
          localStorage.setItem('caregiverPatientDetail', 'Y');
        }
        if (localStorage.getItem('usercategory') === 'FamilyMember') {
          localStorage.setItem('familyMemberPatientDetail', 'Y');
        }

        localStorage.setItem('PatientSubheading', ' Enter cancer Information of ' + patientref.current.value);
        localStorage.setItem('TreatmentSubheading', 'Enter details where ' + patientref.current.value + 'is taking treatment.');



        const result = await response.json();
        localStorage.setItem('patientDetails', 'Y');
        console.log("Patient Details saved successfully:", result);
        onComplete();
      } else {
        console.error("Save failed");
      }
    } catch (error) {
      console.error("Error during save:", error);
    }
  }

  const validateForm = () => {
    let isValid = true;

    if (!patientsName) {
      setPatientError('Name is required');
      isValid = false;
    }

    if (!Age) {
      setAgeError('Age is required');
      isValid = false;
    }

    if (!Gender) {
      setGenderError('Gender is required');
      isValid = false;
    }

    if (!relsWithPatient) {
      setRelsError('Relation is required');
      isValid = false;
    }
    return isValid;
  };


  return (
    <>
      <div>
        <div className='Pagebg'>
          <br />
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4 title'><center>{localStorage.getItem('usercategory')} - Patient Details</center></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <h4>{localStorage.getItem('usercategory').replace("FamilyMember", "As you are Family Member enter the details of patient in your family.").replace("Caregiver", "As you are Caregiver enter the details of patient you taking care.")} </h4>
              <div className='container'>
                <span style={{ color: 'red' }}>*</span>
                <b><label>Patient Name:</label></b>
                <input
                  ref={patientref}
                  type="text"
                  className={`form-control ${errors.patientsName ? 'is-invalid' : ''}`}
                  placeholder="Patient Name"
                  value={patientsName}
                  onChange={(e) => setPatientname(e.target.value)}
                />
                {PatientError && <p style={{ color: 'red' }}>{PatientError}</p>}

                <span style={{ color: 'red' }}>*</span>
                <b><label className='label'>Age:</label></b>
                <select
                  className={`form-control ${errors.Age ? 'is-invalid' : ''}`}
                  value={Age}
                  onChange={(e) => setAge(e.target.value)}
                  ref={ageRef}
                >
                  <option key="default" value="">Select Age</option>
                  {ages.map((age) => (
                    <option key={age} value={age}>{age}</option>
                  ))}
                </select>
                {AgeError && <p style={{ color: 'red' }}>{AgeError}</p>}

                <span style={{ color: 'red' }}>*</span>
                <b><label className='label'>Gender:</label></b>
                <select
                  className={`form-control ${errors.Gender ? 'is-invalid' : ''}`}
                  value={Gender}
                  onChange={(e) => setGender(e.target.value)}
                  ref={genderRef}
                >
                  <option key="default" value="">Select Gender</option>
                  {genders.map((gender) => (
                    <option key={gender} value={gender}>{gender}</option>
                  ))}
                </select>
                {GenderError && <p style={{ color: 'red' }}>{GenderError}</p>}

                <span style={{ color: 'red' }}>*</span><b><label>Relation with Patient:</label></b>
                <input
                  ref={relWithPatientref}
                  type="text"
                  className={`form-control ${errors.relsWithPatient ? 'is-invalid' : ''}`}
                  placeholder="Relation with Patient"
                  value={relsWithPatient}
                  onChange={(e) => setRelPatient(e.target.value)}
                />
                {RelsError && <p style={{ color: 'red' }}>{RelsError}</p>}
                <br />

                <center><button
                  className="btn submit"
                  type="button"
                  onClick={handlesave}
                >
                  Save & Next
                </button></center>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientDetails;
