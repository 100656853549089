import { Table, Modal, Button, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../Components/Cssindex.css';
import Registration from './Registration';
import CheckboxList from './CheckboxList';

const DashBoard = () => {
  const navigate = useNavigate();

  const API_URL = "https://api.cpositive.org/";
  const [checkboxData, setCheckboxData] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [showNextComponent, setShowNextComponent] = useState(false);


  useEffect(() => {
    const fetchCheckboxes = async () => {
      try{
      // const response = await fetch('https://api.cpositive.org/api/CPatient/GetCategory');

      const response = await fetch(API_URL+'api/CPatient/GetCategory');

      const result = await response.json();
      if (Array.isArray(result.data)) {
        const initialCheckboxes = result.data.map(item => ({
          id: item.userCategoryId,
          label: item.categoryName,
          value: 'N',
        }));
        setCheckboxes(initialCheckboxes);
        console.log('Initialized checkboxes:', initialCheckboxes);
      } else {
        console.error('Fetched data is not an array:', result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

    fetchCheckboxes();
  }, []);

  const checkboxValuesRef = useRef([]);

  const handleCheckboxChange = (id) => {
    setCheckboxes(prevState => {
      const updatedCheckboxes = prevState.map(checkbox =>
        checkbox.id === id ? { ...checkbox, value: checkbox.value === 'Y' ? 'N' : 'Y' } : checkbox
      );
      
      checkboxValuesRef.current =updatedCheckboxes;
      // Log the updated checkboxes
      console.log('Updated checkboxes:', updatedCheckboxes);
      return updatedCheckboxes;
    });
  };

  const handleNextClick = () => {
    const hasSelectedCheckbox = checkboxValuesRef.current.some(checkbox => checkbox.value === 'Y');
    if (hasSelectedCheckbox) {
      setShowNextComponent(true);
    } else {
      alert('Please select at least one category.');
    }
  };

  return (
    <>
      {!showNextComponent ? (
        <><br />
        <center> <h3>Select Category</h3><br />
        <hr></hr><br/>
        <h5>Select categories how you were representing yourself as CPositive.</h5></center><br/>
          <div className="categoryOptions">
          <CheckboxList checkboxes={checkboxes}  
          onCheckboxChange={handleCheckboxChange} />
          </div>          
          <br/>
          <br/>          
          <br/>
          <center>
            <button onClick={handleNextClick} className="btn btn-primary submit">
              Next</button>
          </center>
        </>
      ) : (
        <Registration checkboxes={checkboxes} />
      )}
      
      </>
  );
}

export default DashBoard;
