import { useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

const OrganizationDetails = ({ onComplete }) => {

  const navigate = useNavigate();
  const API_URL = "https://api.cpositive.org/";
  const [OrgName, setOrgName] = useState('');
  const [orgWebsite, setOrgWebsite] = useState('');
  const [orgEmail, setOrgEmail] = useState('');
  const [orgMobileNumber, setOrgMobileNumber] = useState('');
  const [orgAddress, setOrgAddress] = useState('');
  const [OrgNameError, setOrgNameError] = useState('');
  const [orgAddressError, setOrgAddressError] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [mobileNoError, setMobileNoError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [FinancialSupport, setFinancialSupport] = useState(false);
  const [MedicalSupport, setMedicalSupport] = useState(false);
  const [LogisticSupport, setLogisticSupport] = useState(false);
  const [CareGiverServices, setCareGiverServices] = useState(false);
  const [MentalHealthSupport, setMentalHealthSupport] = useState(false);
  const [Training, setTraining] = useState(false);
  const [Awareness, setAwareness] = useState(false);
  const [Screening, setScreening] = useState(false);
  const [Other, setOther] = useState(false);
  const [ifOtherTestHere, setifOtherTestHere] = useState('');
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [otherText, setOtherText] = useState('');

  const orgNameRef = useRef(null);
  const orgWebsiteRef = useRef(null);
  const orgEmailRef = useRef(null);
  const orgMobileNumberRef = useRef(null);
  const orgAddressRef = useRef(null);
  const ifOtherTestHereref = useRef(null);

  const handleSave = async () => {
    if (!validateForm()) return;
    let data={};

    if(selectedOption==='option1'){
      data = {
        userId: localStorage.getItem('userId'),
        orgName: '',
        orgWebsite: '',
        orgEmail: '',
        orgMobileNumber: '',
        orgAddress: '',
        isFinancialSupport: FinancialSupport ? 'Y' : 'N',
        isMedicalSupport: MedicalSupport ? 'Y' : 'N',
        isLogisticSupport: LogisticSupport ? 'Y' : 'N',
        isCareGiverServices: CareGiverServices ? 'Y' : 'N',
        isMentalHealthSupport: MentalHealthSupport ? 'Y' : 'N',
        isTraining: Training ? 'Y' : 'N',
        isAwareness: Awareness ? 'Y' : 'N',
        isScreening: Screening ? 'Y' : 'N',
        isOther: Other ? 'Y' : 'N',
        ifOtherTestHere: isOtherChecked ? otherText : '',
      };
    }else{
      data = {
        userId: localStorage.getItem('userId'),
        orgName: orgNameRef.current.value,
        orgWebsite: orgWebsiteRef.current.value,
        orgEmail: orgEmailRef.current.value,
        orgMobileNumber: orgMobileNumberRef.current.value,
        orgAddress: orgAddressRef.current.value,
        isFinancialSupport: FinancialSupport ? 'Y' : 'N',
        isMedicalSupport: MedicalSupport ? 'Y' : 'N',
        isLogisticSupport: LogisticSupport ? 'Y' : 'N',
        isCareGiverServices: CareGiverServices ? 'Y' : 'N',
        isMentalHealthSupport: MentalHealthSupport ? 'Y' : 'N',
        isTraining: Training ? 'Y' : 'N',
        isAwareness: Awareness ? 'Y' : 'N',
        isScreening: Screening ? 'Y' : 'N',
        isOther: Other ? 'Y' : 'N',
        ifOtherTestHere: isOtherChecked ? otherText : '',
      };
    }
    

    try {
      const response = await fetch(API_URL + "api/OtherDetails/OrganizationDetails", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        localStorage.setItem('organizationalDetails', 'Y');
        console.log("organization Details saved successfully:", result);
        onComplete();
      } else {
        const errorData = await response.json();
        console.error("Save failed:", errorData);
      }
    } catch (error) {
      console.error("Error during save:", error);
    }
  };

  const checkMobileNoExists = async (mobileNo) => {
    try {
      const response = await fetch(`${API_URL}api/CPatient/CheckMobileNoExists?mobileno=${mobileNo}`);
      const data = await response.json();
      if (data.exists) {
        setMobileNoError('Mobile number already exists');
      } else {
        setMobileNoError('');
      }
    } catch (error) {
      console.error("Error checking mobile number:", error);
    }
  };

  const debouncedCheckMobileNoExists = useCallback(debounce(checkMobileNoExists, 300), []);

  const handleMobileNumberChange = (e) => {
    const newMobileNo = e.target.value;

    if (/^\d*$/.test(newMobileNo)) {
      setMobileNo(newMobileNo);
      if (newMobileNo.length < 10) {
        setMobileNoError('Mobile number should be at least 10 digits');
      } else {
        setMobileNoError('');
      }
    } else {
      setMobileNoError('Numbers only');
    }

    if (newMobileNo !== "" && newMobileNo.length === 10) {
      debouncedCheckMobileNoExists(newMobileNo);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setOrgEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newEmail)) {
      if(!newEmail)
        {
          setEmailError('');
        }else{
          setEmailError('Invalid email format');
        }
    } else {
      setEmailError('');
    }
  };


 const handleOrgNameChange=(event) => {
  const newOrgName = event.target.value;
  setOrgName(newOrgName);
  if(newOrgName===''){
    setOrgNameError('');
  }else{
    setOrgNameError('');
  }
 }

  const handleAddressChange=(event) => {
const newAddress = event.target.value;
setOrgAddress(newAddress);
if(newAddress===''){
  setOrgAddressError('');
  }else{
    setOrgAddressError('');
  }
  }

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsOtherChecked(isChecked);
    setOther(isChecked);
    if (!isChecked) {
      setOtherText('');
    }
  };

  const [selectedOption, setSelectedOption] = useState('option1');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const validateForm = () => {
    let isValid = true;
    if(selectedOption === 'option1'){
    }else{
      if (!OrgName) {
        setOrgNameError('Name is required');
        isValid = false;
      }
  
      if (!orgAddress) {
        setOrgAddressError('Select country');
        isValid = false;
      }
    }
    return isValid;
  };

  return (
    <>
      <div>
        <div className='Pagebg'>
          <br />
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6 title'><center>Volunteering</center></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              
              <div className='container'>
                  <div>
                  <div className='row'>
                    <div className='col-md-5'>
                <label className='radio-label'>
                  <input
                    type="radio"
                    value="option1"

                    checked={selectedOption === 'option1'}
                    onChange={handleOptionChange}
                  /><b>
                  Volunteering as Individual</b>
                </label>
              </div>
              
              <div className='col-md-6'>
                <label className='radio-label'>
                  <input 
                    type="radio"
                    value="option2"
                    checked={selectedOption === 'option2'}
                    onChange={handleOptionChange}
                  /><b>
                  Volunteering with Organization</b>
                </label>
              </div>
              </div>


              {selectedOption === 'option2' && (
        <div style={{ marginTop: '20px' }}>
                   
                <div className='row'>
                  <h4 className='title-bar'>Organization Details</h4>

                  <div className='col-md-6'>
                    <span style={{ color: 'red' }}>*</span> <b><label>Organization Name:</label></b>
                    <input
                      ref={orgNameRef}
                      type="text"
                      className="form-control"
                      placeholder="Organization Name"
                      value={OrgName}
                      onChange={handleOrgNameChange}
                    />
                    {OrgNameError && <p style={{ color: 'red' }}>{OrgNameError}</p>}
                  </div>

                  <div className='col-md-6'>
                    <b><label>Website:</label></b>
                    <input
                      ref={orgWebsiteRef}
                      type="text"
                      className="form-control"
                      placeholder="Organization Website"
                      value={orgWebsite}
                      onChange={(e) => setOrgWebsite(e.target.value)}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <b><label>Email Id:</label></b>
                    <input
                      ref={orgEmailRef}
                      type="text"
                      className="form-control"
                      placeholder="Organization Email"
                      value={orgEmail}
                      onChange={handleEmailChange}
                    />
                    {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                  </div>

                  <div className='col-md-6'>
                    <b><label>Mobile No.:</label></b>
                    <input
                      ref={orgMobileNumberRef}
                      type="text"
                      className="form-control"
                      placeholder="Mobile No."
                      value={mobileNo}
                      onChange={handleMobileNumberChange}
                    />
                    {mobileNoError && <p style={{ color: 'red' }}>{mobileNoError}</p>}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <span style={{ color: 'red' }}>*</span><b><label>Address:</label></b>
                    <input
                      ref={orgAddressRef}
                      type="text"
                      className="form-control"
                      placeholder="Organization Address"
                      value={orgAddress}
                      onChange={handleAddressChange}
                    />
                    {orgAddressError && <p style={{ color: 'red' }}>{orgAddressError}</p>}
                  </div>
                </div>
        </div>
      )}


                <div className='row'>
                  <br/>
                  <br/>
                  <h4 className='title-bar'>Area of Service</h4>
                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={FinancialSupport}
                        onChange={(e) => setFinancialSupport(e.target.checked)}
                      />
                      Financial Support
                    </label>
                  </div>

                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={MedicalSupport}
                        onChange={(e) => setMedicalSupport(e.target.checked)}
                      />
                      Medical Support
                    </label>
                  </div>

                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={LogisticSupport}
                        onChange={(e) => setLogisticSupport(e.target.checked)}
                      />
                      Logistic Support
                    </label>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={CareGiverServices}
                        onChange={(e) => setCareGiverServices(e.target.checked)}
                      />
                      Care Giver Services
                    </label>
                  </div>

                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={MentalHealthSupport}
                        onChange={(e) => setMentalHealthSupport(e.target.checked)}
                      />
                      Mental Health Support
                    </label>
                  </div>

                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={Training}
                        onChange={(e) => setTraining(e.target.checked)}
                      />
                      Training
                    </label>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={Awareness}
                        onChange={(e) => setAwareness(e.target.checked)}
                      />
                      Awareness
                    </label>
                  </div>

                  <div className='col-md-4'>
                    <label>
                      <input
                        type="checkbox"
                        checked={Screening}
                        onChange={(e) => setScreening(e.target.checked)}
                      />
                      Screening
                    </label>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-8'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isOtherChecked}
                        onChange={handleCheckboxChange}
                      />
                      Other
                    </label>
                    {isOtherChecked && (
                      <div>
                        <label>If Other Test here:</label>
                        <input
                          ref={ifOtherTestHereref}
                          type="text"
                          className="form-control"
                          value={otherText}
                          onChange={(e) => setOtherText(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <center><button
                  className="btn submit"
                  type="button"
                  onClick={handleSave}
                >
                  Save & Next
                </button></center>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default OrganizationDetails;
