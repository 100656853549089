import React, { useEffect, useState } from 'react';

function FinalPage() {
  const [personalDetails, setPersonalDetails] = useState(null);
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [UserCpatientCategoryDetails, setUserCpatientCategoryDetails] = useState(null);
  const [UserCaregiveCategoryDetails, setUserCaregiveCategoryDetails] = useState(null);
  const [UserFamilyMemberCategoryDetails, setUserFamilyMemberCategoryDetails] = useState(null);
  const [UserHealthcareProfessionalCategoryDetails, setUserHealthcareProfessionalCategoryDetails] = useState(null);
  const [UserMentalHealthProfessionalCategoryDetails, setUserMentalHealthProfessionalCategoryDetails] = useState(null);

  const API_URL = "https://api.cpositive.org/";
  const userId = localStorage.getItem('userId');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (userId) {
      fetchData('api/OtherDetails/PersonalDetails', setPersonalDetails);
      fetchData('api/OtherDetails/OrganizationDetails', setOrganizationDetails);
      fetchData('api/OtherDetails/UserCpatientCategoryDetails', setUserCpatientCategoryDetails);
      fetchData('api/OtherDetails/UserCaregiveCategoryDetails', setUserCaregiveCategoryDetails);
      fetchData('api/OtherDetails/UserFamilyMemberCategoryDetails', setUserFamilyMemberCategoryDetails);
      fetchData('api/OtherDetails/UserHealthcareProfessionalCategoryDetails', setUserHealthcareProfessionalCategoryDetails);
      fetchData('api/OtherDetails/UserMentalHealthProfessionalCategoryDetails', setUserMentalHealthProfessionalCategoryDetails);
    }
  }, [userId]);

  const fetchData = (endpoint, setState) => {
    fetch(`${API_URL}${endpoint}/${userId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
      })
      .then(data => {
        setState(data);
        console.log("check data : ", data);
      })
      .catch(error => console.error(`Error fetching ${endpoint}:`, error));
  };

  const renderPersonalDetails = (data) => (

    <div style={styles.box}>
      {/* <center><h3 className='py-2 title-name'>Personal Details</h3><br /></center> */}     
      <h3>Personal Details</h3>
      <hr /><br/> <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Name:</span></p>
          <div className='name'>{data.name}
          </div>
        </div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Email:</span></p>
          <div className='name'> {localStorage.getItem('emailId')}
          </div>
        </div>
      </div>


      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Age:</span> </p>
          <div className='name'>{data.age}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Gender:</span> </p>
          <div className='name'>{data.gender}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Highest Qualification:</span> </p>
          <div className='name'>{data.highestQualification}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Occupation:</span> </p>
          <div className='name'>{data.occupation}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Country:</span> </p>
          <div className='name'>{personalDetails.countryName[0]}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>State:</span></p>
          <div className='name'>{personalDetails.stateName[0]}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>District:</span> </p>
          <div className='name'>{personalDetails.districtName[0]}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Address:</span> </p>
          <div className='name'>{data.address}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Pincode:</span> </p>
          <div className='name'>{data.pincode}
          </div></div>
        <div className='col-md-5 flex'>


        </div>
      </div>


    </div>
  );
  // *******************
  const renderCpatientCategoryDetails = (data) => (
    <div style={styles.box}>
      {/* <center><h3 className='py-2 title-name'>CPatient</h3><br /></center> */}
<h3>CPatient</h3><hr/><br/>
      <div className='row'>
        <p className='name'><u>Your Cancer Information</u></p>
        <br /><br />
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Cancer Type:</span></p>
          <div className='name'>{data.cancerType}
          </div>
        </div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Cancer Name:</span></p>
          <div className='name'> {data.cancerName}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Stage:</span></p>
          <div className='name'> {data.stage}
          </div>
        </div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Grade:</span> </p>
          <div className='name'>{data.grade}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>First Time:</span> </p>
          <div className='name'>{data.data[0].isFirstTime?.trim() === 'Y' ? 'Yes' : 'No'}
            {console.log("hilseseg : " + data.data[0].isFirstTime)}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Relapsed:</span> </p>
          <div className='name'>{data.data[0].isRelapsed?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Ongoing Treatment :</span></p>
          <div className='name'>{data.data[0].isTreatmentOngoing?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Surgery:</span> </p>
          <div className='name'>{data.data[0].isSurgery?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Chemo:</span></p>
          <div className='name'>{data.data[0].isChemo?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Radiation:</span></p>
          <div className='name'>{data.data[0].isRadiation?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>

        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Targeted Therapy:</span> </p>
          <div className='name'>{data.data[0].isTargetedTherapy?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Palliative Care:</span> </p>
          <div className='name'>{data.data[0].isPallitiveCare?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Remission:</span> </p>
          <div className='name'>{data.data[0].isRemission?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className='row'>
        <p className='name'><u>Your Treatment Information</u></p>
        <br /><br />
        {data.treatmentConductedAt.map((treatment, index) => (
          <React.Fragment key={index}>
            <div className='col-md-1'></div>
            <div className='col-md-5 flex'>
              <p style={styles.detail}>
                <span style={styles.label}>Hospital Name:</span>
              </p>
              <div className='name'>
                {treatment.hospitalName}
              </div>
            </div>
            <div className='col-md-5 flex'>
              <p style={styles.detail}>
                <span style={styles.label}>Oncologist Name:</span>
              </p>
              <div className='name'>
                {treatment.oncologistName}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
  // *******************

  // *******************

  const renderCaregiverCategoryDetails = (data) => (
    <div style={styles.box}>
      <h3>Caregiver</h3><hr/><br/>
      <div className='row'>
        <p className='name'><u>Patient Information</u></p>
       
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Patient Name:</span> </p>
          <div className='name'>{data.patientDetails[0].patientName}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Gender:</span> </p>
          <div className='name'>{data.patientDetails[0].gender}
          </div>
        </div>
      </div>

      <div className='row'>

        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Age:</span> </p>
          <div className='name'>{data.patientDetails[0].age}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Relationship with Patient:</span> </p>
          <div className='name'>{data.patientDetails[0].relWithPatient}
          </div>
        </div>
      </div>
      <br />

      <div className='row'>
      <p className='name'><u>Cancer Information Of Patient</u></p>
          <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Cancer Type:</span></p>
          <div className='name'>{data.cancerType}
          </div>
        </div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Cancer Name:</span></p>
          <div className='name'> {data.cancerName}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Stage:</span></p>
          <div className='name'> {data.stage}
          </div>
        </div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Grade:</span> </p>
          <div className='name'>{data.grade}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>First Time:</span> </p>
          <div className='name'>{data.data[0].isFirstTime?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Relapsed:</span> </p>
          <div className='name'>{data.data[0].isRelapsed?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Ongoing Treatment :</span></p>
          <div className='name'>{data.data[0].isTreatmentOngoing?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Surgery:</span> </p>
          <div className='name'>{data.data[0].isSurgery?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Chemo:</span></p>
          <div className='name'>{data.data[0].isChemo?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Radiation:</span></p>
          <div className='name'>{data.data[0].isRadiation?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>

        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Targeted Therapy:</span> </p>
          <div className='name'>{data.data[0].isTargetedTherapy?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Palliative Care:</span> </p>
          <div className='name'>{data.data[0].isPallitiveCare?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Remission:</span> </p>
          <div className='name'>{data.data[0].isRemission?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>
      <br />
      <div className='row'>
        <p className='name'><u>Patient Treatment Conducted At</u></p>

        {data.treatmentConductedAt.map((treatment, index) => (
          <React.Fragment key={index}>
            <div className='col-md-1'></div>
            <div className='col-md-5 flex'>
              <p style={styles.detail}>
                <span style={styles.label}>Hospital Name:</span>
              </p>
              <div className='name'>
                {treatment.hospitalName}
              </div>
            </div>
            <div className='col-md-5 flex'>
              <p style={styles.detail}>
                <span style={styles.label}>Oncologist Name:</span>
              </p>
              <div className='name'>
                {treatment.oncologistName}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>

    </div>
  );

  // *******************


  // *******************
  const renderFamilyMemberCategoryDetails = (data) => (
    <div style={styles.box}>
      {/* <center><h3 className='title-name'>Family Member</h3></center><br /> */}
      <h3>Family Member</h3><hr/><br/>

      <div className='row'>
        <p className='name'><u>Patient Information</u></p>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Patient Name:</span> </p>
          <div className='name'>{data.patientDetails[0].patientName}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Gender:</span> </p>
          <div className='name'>{data.patientDetails[0].gender}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Age:</span> </p>
          <div className='name'>{data.patientDetails[0].age}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Relationship with Patient:</span> </p>
          <div className='name'>{data.patientDetails[0].relWithPatient}
          </div>
        </div>
      </div>
      <br />


      <div className='row'>
      <p className='name'><u>Cancer Information Of Patient</u></p>

        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Cancer Type:</span></p>
          <div className='name'>{data.cancerType}
          </div>
        </div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Cancer Name:</span></p>
          <div className='name'> {data.cancerName}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Stage:</span></p>
          <div className='name'> {data.stage}
          </div>
        </div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Grade:</span> </p>
          <div className='name'>{data.grade}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>First Time:</span> </p>
          <div className='name'>{data.data[0].isFirstTime?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Relapsed:</span> </p>
          <div className='name'>{data.data[0].isRelapsed?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Ongoing Treatment :</span></p>
          <div className='name'>{data.data[0].isTreatmentOngoing?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Surgery:</span> </p>
          <div className='name'>{data.data[0].isSurgery?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Chemo:</span></p>
          <div className='name'>{data.data[0].isChemo?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Radiation:</span></p>
          <div className='name'>{data.data[0].isRadiation?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>

        </div>
      </div>

      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Targeted Therapy:</span> </p>
          <div className='name'>{data.data[0].isTargetedTherapy?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Palliative Care:</span> </p>
          <div className='name'>{data.data[0].isPallitiveCare?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Remission:</span> </p>
          <div className='name'>{data.data[0].isRemission?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>
      <br />
      <div className='row'>
        <p className='name'><u>Patient Treatment Conducted At</u></p>
        {data.treatmentConductedAt.map((treatment, index) => (
          <React.Fragment key={index}>
            <div className='col-md-1'></div>
            <div className='col-md-5 flex'>
              <p style={styles.detail}>
                <span style={styles.label}>Hospital Name:</span>
              </p>
              <div className='name'>
                {treatment.hospitalName}
              </div>
            </div>
            <div className='col-md-5 flex'>
              <p style={styles.detail}>
                <span style={styles.label}>Oncologist Name:</span>
              </p>
              <div className='name'>
                {treatment.oncologistName}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>

    </div>
  );
  // *******************


  // *******************


  const renderOrganizationDetails = (data) => (
    <div style={styles.box}>
      {/* <center><h3 className='title-name'>Vounteering</h3></center><br /> */}
      <h3>Vounteering</h3><hr/><br/>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Organization Name:</span> </p>
          <div className='name'>{data.data[0].orgName}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Website:</span></p>
          <div className='name'>{data.data[0].orgWebsite}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Email:</span> </p>
          <div className='name'>{data.data[0].orgEmail}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Mobile Number:</span> </p>
          <div className='name'>{data.data[0].orgMobileNumber}
          </div>
        </div>
      </div>
      <br />
      <p className='name'><u>Area of service</u></p>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Financial Support:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isFinancialSupport.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Medical Support:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isMedicalSupport?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Logistic Support:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isLogisticSupport?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>CareGiver Services:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isCareGiverServices?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>MentalHealth Support:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isMentalHealthSupport?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Training:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isTraining?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Awareness:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isAwareness?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Screening:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isScreening?.trim() === 'Y' ? 'Yes' : 'No'}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Other:</span> </p>
          <div className='name'>{data.areaOfServiceDetails[0].isOther?.trim() === 'Y' ? 'Yes' : 'No'}
          </div></div>
        <div className='col-md-5 flex'>
        </div>
      </div>
    </div>
  );

  // *******************

  // *******************

  const renderHealthcareProfessionalCategoryDetails = (data) => (
    <div style={styles.box}>
      {/* <center><h3 className='title-name'>Healthcare Professional</h3></center><br /> */}
      <h3>Healthcare Professional</h3><hr/><br/>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Qualification:</span> </p>
          <div className='name'>{data.qualification}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Specilization:</span> </p>
          <div className='name'>{data.specilization}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Experties:</span> </p>
          <div className='name'>{data.experties}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Experience:</span> </p>
          <div className='name'>{data.experience}
          </div>
        </div>
      </div>

    </div>
  );

  // *******************

  const renderMentalHealthProfessionalCategoryDetails = (data) => (
    <div style={styles.box}>
      {/* <center><h3 className='title-name'>Mental Health Professional</h3></center><br /> */}
      <h3>Mental Health Professional</h3><hr/><br/>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Qualification:</span> </p>
          <div className='name'>{data.qualification}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Specilization:</span> </p>
          <div className='name'>{data.specilization}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Experties:</span> </p>
          <div className='name'>{data.experties}
          </div></div>
        <div className='col-md-5 flex'>
          <p style={styles.detail}><span style={styles.label}>Experience:</span> </p>
          <div className='name'>{data.experience}
          </div>
        </div>
      </div>

    </div>
  );

  // *******************


  return (
    <div style={{ backgroundColor: '#2f4f4f'}}>    
    <div style={styles.container}>
      <div style={styles.header}>
        {personalDetails && personalDetails.data[0].imagePath && (
          <>
            <img
              src={`${API_URL}api/OtherDetails/ProfileImage${personalDetails.data[0].imagePath.replace(/\\/g, '/').replace(/images/g, '')}`}
              alt="User"
              style={{ ...styles.userPhoto, ...(isLoaded ? styles.userPhotoVisible : {}) }}
          onLoad={() => setIsLoaded(true)}
              onError={(e) => {
                console.error('Image load error:', e);
                console.error('Image URL:', `${API_URL}api/OtherDetails/ProfileImage${personalDetails.data[0].imagePath.replace(/\\/g, '/').replace(/images/g, '')}`);
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = `http://via.placeholder.com/100`; // Fallback image URL
              }}
            />

          </>
        )}
      </div>
      <div style={{ ...styles.dataContainer, ...(isLoaded ? styles.dataContainerVisible : {}) }}>
      {personalDetails && renderPersonalDetails(personalDetails.data[0])}
        {UserCpatientCategoryDetails && renderCpatientCategoryDetails(UserCpatientCategoryDetails)}
        {UserCaregiveCategoryDetails && renderCaregiverCategoryDetails(UserCaregiveCategoryDetails)}
        {UserFamilyMemberCategoryDetails && renderFamilyMemberCategoryDetails(UserFamilyMemberCategoryDetails)}
        {organizationDetails && renderOrganizationDetails(organizationDetails)}
        {UserHealthcareProfessionalCategoryDetails && renderHealthcareProfessionalCategoryDetails(UserHealthcareProfessionalCategoryDetails.data[0])}
        {UserMentalHealthProfessionalCategoryDetails && renderMentalHealthProfessionalCategoryDetails(UserMentalHealthProfessionalCategoryDetails.data[0])}                
      </div>
    </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    textAlign: 'center',
    transition: 'opacity 1s ease-in-out',
  },
  header: {
    display: 'inline',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: '20px'
  },
  userPhoto: {
    width: '250px',
    height: '250px',
    borderRadius: '50%',
    objectFit: 'cover',
    border: '3px solid white',
    opacity: 0, 
    transition: 'opacity 1s ease-in-out', 
  },
  userPhotoVisible: {
    opacity: 1, 
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
  },
  dataContainerVisible: {
    opacity: 1,
  },
  box: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px',
    width: '80%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'left',
    backgroundColor: 'white',
  },
  detail: {
    fontSize: '18px', // Bigger font size for both label and value
    marginBottom: '10px'
  },
  label: {
    fontWeight: 'bold', // Bold labels
    marginRight: '10px'
  }
};

export default FinalPage;
